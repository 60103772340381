import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import EvaluationResults from "./pages/EvaluationResults";
import CreatePage from "./pages/CreatePage";
import Navigation from "./components/Navigation";
import 'bootstrap/dist/css/bootstrap.css';
import HomeActual from "./pages/HomeActual";
function App() {
  return (
      <Router>
          <Navigation />
        <Routes>
          <Route path="/" element={<HomeActual />} />
          <Route path="/evaluation/:id" element={<EvaluationResults />} />
          <Route path="/results" element={<Home />} />
          <Route path="/create" element={<CreatePage />} />
        </Routes>
      </Router>
  );
}

export default App;
