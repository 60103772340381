import React from "react";

import { Card } from "react-bootstrap";

import "../App.css";
import {Link} from "react-router-dom";


function HomeActual() {


    return (
        <div className="page-background" >
            <Card className="m-4 custom-card">
                <Card.Body>
                    <Card.Title>Evaluations</Card.Title>
                    <Card.Text>
                        Welcome to LLM-Thunderdome. Two LLM's enter, one LLM leaves.
                    </Card.Text>
                    <Card.Text>
                        This little mamma-jamma (esp wrt the unitext) is powered by <Link to="https://github.com/yoavkatz/lm-evaluation-harness/tree/main">Yoav's Fork of LM-Eval.</Link>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
}

export default HomeActual;
