import React, { useState } from "react";
import { Button, Form, Row, Col, Card, Accordion } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import app from "../firebase-config"; // Ensure this path is correct
import { useNavigate } from 'react-router-dom';

import "../App.css";
import { taskArray, unitxtTasks } from "../assets/tasks";
function CreatePage() {
    // Initialize task selections as false
    const initialTaskState = taskArray.reduce((acc, task) => {
        acc[task] = false;
        return acc;
    }, {});

    const [formData, setFormData] = useState({
        model: "openai-completions",
        model_args: "model=davinci-002",
        ...initialTaskState,
    });

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();


        const functions = getFunctions(app);
        const callEval = httpsCallable(functions, 'call_eval');

        // Create a copy of formData
        const submissionData = { ...formData };

        // Generate a comma-separated string of selected tasks
        const selectedTasks = taskArray.filter(task => submissionData[task]).join(',');

        // Remove individual task fields from submissionData
        taskArray.forEach(task => {
            delete submissionData[task];
        });

        // Add the tasks string to submissionData
        submissionData.tasks = selectedTasks;

        try {
            console.log(submissionData);
            const result = await callEval(submissionData);
            console.log(result); // Handle response
            if (result.data.ticket) {
                navigate(`/evaluation/${result.data.ticket}`);
            }
        } catch (error) {
            console.error("Error calling Firebase function:", error);
        }
    };

    return (
        <div className="page-background">
            <Card className="m-4 custom-card">
                <Card.Header>Create Evaluation</Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Model</Form.Label>
                                <Form.Select
                                    name="model"
                                    value={formData.model}
                                    onChange={handleChange}
                                >
                                    <option value="openai-completions">OpenAI (Limit 50, get your own key)</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Model Args</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="model_args"
                                    value={formData.model_args}
                                    disabled
                                />
                            </Form.Group>
                        </Row>
                        <Form.Group className="mb-3">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Task Selection</Accordion.Header>
                                    <Accordion.Body>
                                        {taskArray.map((task, idx) => (
                                            <Form.Check
                                                type="checkbox"
                                                label={
                                                    `${task} ${unitxtTasks.includes(task) ? '(UniTxt Task)' : ''}`
                                                    // This adds '(UniTxt Task)' next to the task name if it's a UniTxt task
                                                }
                                                name={task}
                                                key={idx}
                                                onChange={handleChange}
                                                checked={formData[task]}
                                            />
                                        ))}


                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Form.Group>
                        <Button disabled={true} variant="primary" type="submit">
                            Submit (Disabled)
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
}

export default CreatePage;
