
export const taskArray = [
    "20_newsgroups",
    "ag_news",
    "banking77",

    "openbookqa",
    "xsum",
    "yahoo_answers_topics",
    "unitxt",
    "unfair_tos",
];

export const unitxtTasks = [
    "20_newsgroups",
    "ag_news",
    "banking77",

    "unfair_tos",
    "unitxt",
];

