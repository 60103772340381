// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCSo083nr3rBb4sJCAGRETWbfpEttg9qkI",
    authDomain: "llm-thunderdome.firebaseapp.com",
    projectId: "llm-thunderdome",
    storageBucket: "llm-thunderdome.appspot.com",
    messagingSenderId: "870365595677",
    appId: "1:870365595677:web:ffaaa6b0426ec941521ef4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;

